<template>
  <v-card flat>
    <v-card-title class="pb-0">
      <v-row
        v-if="week === 'present'"
        no-gutters
        justify="center"
        class="pb-0"
      >
        <v-select
          v-model="entry.date"
          prepend-inner-icon="mdi-calendar-month"
          label="Date"
          item-value="date"
          filled
          :disabled="isApproved"
          :value="$store.state.weekly.selectedDay"
          :items="thisWeekWithoutFutureDates"
          :rules="rules.date"
        >
          <template #item="props">
            {{ `${props.item.dayName } ${ props.item.date}` }}
          </template>
          <template #selection="selectProps">
            {{ `${selectProps.item.dayName } ${ selectProps.item.date}` }}
          </template>
        </v-select>
      </v-row>
      <v-row
        v-if="week === 'last'"
        no-gutters
        justify="center"
      >
        <v-select
          v-model="entry.date"
          prepend-inner-icon="mdi-calendar-month"
          label="Date"
          item-value="date"
          filled
          :disabled="isApproved"
          :value="$store.state.weekly.selectedDay"
          :items="$store.getters.lastWeekDates"
          :rules="rules.date"
        >
          <template #item="props">
            {{ `${props.item.dayName } ${ props.item.date}` }}
          </template>
          <template #selection="selectProps">
            {{ `${selectProps.item.dayName } ${ selectProps.item.date}` }}
          </template>
        </v-select>
      </v-row>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-form
        v-if="entry"
        ref="form"
        v-model="isValid"
        lazy-validation
        :disabled="isApproved"
      >
        <!-- Job Input -->
        <v-autocomplete
          v-model="entry.job_num"
          prepend-inner-icon="mdi-crane"
          label="Select Job Number"
          filled
          attach
          dense
          :items="jobList"
          :rules="rules.job_num"
          @change="blurInputs()"
        />

        <!-- Truck Input -->
        <v-autocomplete
          v-model="entry.truck"
          prepend-inner-icon="mdi-crane"
          label="Truck"
          filled
          attach
          dense
          :items="truckList"
          :rules="rules.truck"
          @change="blurInputs()"
        />

        <!-- Employee Input -->
        <v-autocomplete
          v-model="entry.emp_num"
          filled
          attach
          dense
          prepend-inner-icon="mdi-crane"
          label="Employee"
          :items="employeeList"
          :rules="rules.emp_num"
        />

        <!-- Equipment Input -->
        <v-autocomplete
          v-model="entry.eq_num"
          filled
          dense
          attach
          prepend-inner-icon="mdi-dump-truck"
          label="Equipment"
          :items="equipmentList"
          :rules="rules.eq_num"
          @change="handleChangeEquipmentNumber"
        />

        <!-- Equipment primary unit of measurement -->
        <v-select
          v-if="entry.primary_unit_measure"
          v-model="entry.primary_unit_measure"
          dense
          disabled
          hide-details
          class="mb-1"
          append-icon=""
          label="Primary Unit Measure"
          :rules="rules.current_hours"
          :items="primaryUnitsOfMeasurement"
        />
        <v-row>
          <v-col>
            <!-- Hours input -->
            <v-text-field
              v-model="entry.current_hours"
              filled
              dense
              prepend-inner-icon="mdi-clock"
              type="number"
              inputmode="decimal"
              hide-spin-buttons
              label="Hours"
              min="0"
              step="0.25"
              :rules="rules.current_hours"
              @keypress="onlyNumsAllowed($event)"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <!-- Miles input -->
            <v-text-field
              v-model="entry.current_miles"
              filled
              dense
              prepend-inner-icon="mdi-road"
              type="number"
              inputmode="decimal"
              hide-spin-buttons
              label="Miles"
              min="0"
              step="0.25"
              :rules="rules.current_miles"
              @keypress="onlyNumsAllowed($event)"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <!-- Gallons input -->
        <v-text-field
          v-model="entry.gallons"
          filled
          dense
          prepend-inner-icon="mdi-gas-station-outline"
          type="number"
          inputmode="decimal"
          hide-spin-buttons
          label="Gallons"
          min="0"
          step="0.25"
          :rules="rules.gallons"
          @keypress="onlyNumsAllowed($event)"
        >
        </v-text-field>

        <!-- Note input -->
        <v-textarea
          v-model="entry.note"
          rows="1"
          label="Notes"
          auto-grow
          filled
          :rules="rules.note"
        >
        </v-textarea>
      </v-form>
    </v-card-text>

    <!-- CANCEL AND SAVE BUTTONS -->
    <h3
      v-if="isApproved"
      style="color: red"
      class="mx-2"
    >
      Entry has been approved and can't be edited
    </h3>
    <v-card-actions class="pt-0">
      <v-flex align-self-end>
        <v-spacer></v-spacer>

        <!-- Delete btn -->
        <v-btn
          v-if="existingRecord"
          x-large
          color="error"
          class="ma-2"
          :disabled="isApproved"
          @click="handlePressDelete"
        >
          <v-icon left>
            mdi-delete
          </v-icon>delete
        </v-btn>

        <!-- Cancel btn -->
        <v-btn
          color="warning"
          class="ma-2"
          x-large
          @click="close"
        >
          <v-icon left>
            mdi-close
          </v-icon>cancel
        </v-btn>

        <!-- Save btn -->
        <v-btn
          id="reviewButton"
          color="success"
          class="ma-2"
          x-large
          :loading="saving"
          :disabled="!isValid || isApproved"
          @click="sendEntry(false)"
        >
          <v-icon left>
            mdi-cloud-upload-outline
          </v-icon>
          Save
        </v-btn>
      </v-flex>
    </v-card-actions>
    <Alertbox
      v-bind="alert"
      @close="alert.display=$event; saving = false"
      @save="alert.display=$event; sendEntry(true)"
    />
  </v-card>
</template>
<script>
import moment from 'moment/src/moment';
import fetchDataNewAPI from '../../mixins/fetchDataNewAPI.js';
import Alertbox from '../alert/alert.vue';

export default {
  name: 'SlipTankEntry',
  components: {
    Alertbox,
  },
  mixins: [fetchDataNewAPI],
  props: {
    loggedInUser: {
      type: String,
      required: true,
    },
    truck: {
      type: String,
      required: false,
      default: null,
    },
    week: {
      type: String,
      required: true,
    },
    existingRecord: {
      type: Boolean,
      required: true,
    },
    recordClickedOn: {
      type: Object,
      required: true,
    },
    locations: {
      type: Array,
      required: true,
    },
    equipment: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      primaryUnitsOfMeasurement: [
        {
          text: 'Miles',
          value: '1',
        }, {
          text: 'Hours',
          value: '0',
        },
      ],
      entry: {
        id: null,
        emp_num: null,
        date: '',
        truck: null,
        job_num: '',
        location_id: null,
        eq_num: '',
        primary_unit_measure: '',
        gallons: '',
        approved: 0,
        current_hours: null,
        current_miles: null,
        note: null,
      },
      allTrucks: [],
      allEmployees: [],
      alert: {
        display: false,
        message: '',
        confirm: false,
      },
      saving: false,
      isValid: false,
      rules: {
        emp_num: [
          (v) => {
            return !!v || 'Required';
          },
        ],
        job_num: [
          (v) => {
            return !!v || 'Required';
          },
        ],
        eq_num: [
          (v) => {
            return !!v || 'Required';
          },
        ],
        truck: [
          (v) => {
            return !!v || 'Required';
          },
        ],
        location_id: [
          (v) => {
            return !!v || 'Required';
          },
        ],
        date: [
          (v) => {
            return !!v || 'Required';
          },
        ],
        current_hours: [
          (v) => {
            return !Number.isNaN(v) || 'Must be a number';
          }, (v) => {
            return !v || Math.floor(v).toString().length <= 8 || 'Max 8 Whole Numbers';
          },
        ],
        current_miles: [
          (v) => {
            return !Number.isNaN(v) || 'Must be a number';
          }, (v) => {
            return !v || Math.floor(v).toString().length <= 8 || 'Max 8 Whole Numbers';
          },
        ],
        gallons: [
          (v) => {
            return !!v || 'Required';
          },
          (v) => {
            return !Number.isNaN(v) || 'Must be a number';
          }, (v) => {
            return !v || Math.floor(v).toString().length <= 3 || 'Max 3 Whole Numbers';
          },
        ],
        note: [
          (v) => {
            return !v || v.length < 255 || 'Max 255 characters';
          },
        ],
      },
    };
  },
  computed: {
    isApproved() {
      return Number(this.entry?.approved) === 1;
    },

    thisWeekWithoutFutureDates() {
      const today = moment().format('YYYY-MM-DD');
      return this.$store.getters.dates.filter((day) => {
        return day.date <= today;
      });
    },

    equipmentList() {
      const textArray = this.$store.state.shared.equipment
        .map((eq) => {
          return { text: `${eq.eq_num} ${eq.description}`, value: eq.eq_num };
        });
      if (this.$store.state.shared.jobEquipment[this.entry.job_num]) {
        const jobEquipment = this.$store.state.shared.jobEquipment[this.entry.job_num]
          .map((eq) => {
            return { text: eq.description, value: eq.eq_num };
          });
        return [
          { header: 'Job Equipment' },
          ...jobEquipment,
          { divider: true },
          { header: 'All Equipment' },
          ...textArray,
        ];
      }
      return [
        { header: 'All Equipment' },
        ...textArray,
      ];
    },

    jobList() {
      const j = this.locations;
      return j.map((e) => {
        return { value: e.job_num, text: e.description };
      });
    },

    truckList() {
      if (!this.allTrucks) {
        return [];
      }

      const j = this.allTrucks.filter((e) => {
        return [1, 2].includes(Number(e.status));
      });
      return j.map((eq) => {
        return { value: eq.eq_num, text: `${eq.eq_num} - ${eq.description}` };
      });
    },

    employeeList() {
      const j = this.allEmployees;
      return j.map((e) => {
        return { value: e.emp_num, text: `${e.emp_num} - ${e.fstnme} ${e.lstnme}` };
      });
    },
  },

  async created() {
    this.init();
  },

  methods: {
    ////////////////////////////
    // INIT AND REFRESH METHODS
    ////////////////////////////

    async init() {
      if (this.existingRecord) {
        this.entry = {
          ...this.recordClickedOn,
          primary_unit_measure: this.recordClickedOn?.primary_unit_measure === '1' ? '1' : '0',
        };
      }

      this.allTrucks = await this.getAllTrucks();
      this.allEmployees = this.$store.getters.employees;
      if (!this.entry.emp_num) {
        this.entry.emp_num = this.loggedInUser;
      }
      if (!this.entry.truck) {
        this.entry.truck = this.truck;
      }
      if (!this.entry.date) {
        this.entry.date = moment().format('YYYY-MM-DD');
      }

      // Update the validation rules based on the selected equipment
      this.$nextTick(() => {
        this.updateRules();
      });
    },

    ////////////////////////////
    // HANDLER FUNCTIONS
    ////////////////////////////

    handleChangeEquipmentNumber(val) {
      const equipment = this.equipment.find((e) => {
        return e.eq_num === val;
      });
      this.entry.primary_unit_measure = Number(equipment?.primary_unit_measure) ? '1' : '0';
      this.updateRules();
      this.blurInputs();
    },

    async handlePressDelete() {
      await this.deleteSliptankRecord(this.entry.id);
      this.close();
    },

    ////////////////////////////
    // LOGIC / HELPER FUNCTIONS
    ////////////////////////////

    // Updates the rules based on the selected equipment
    updateRules() {
      // Remove the optional required rule from both hours and miles
      if (this.rules.current_hours.length === 3) {
        this.rules.current_hours.pop();
      }
      if (this.rules.current_miles.length === 3) {
        this.rules.current_miles.pop();
      }

      // Add the "required" rule to miles or hours based on the selected equipment
      if (this.entry.eq_num) {
        if (this.entry.primary_unit_measure === '0') {
          this.rules.current_hours.push((v) => {
            return !!v || 'Required';
          });
        } else {
          this.rules.current_miles.push((v) => {
            return !!v || 'Required';
          });
        }
      }
      this.$refs.form.validate();
    },

    onlyNumsAllowed(evt) {
      // Only works if using @keypress
      if (!parseFloat(evt.key) && evt.key !== '0' && evt.key !== '.') {
        evt.preventDefault();
      }
    },

    // Blurs inputs, closing the keyboard
    blurInputs() {
      document.activeElement.blur();
    },

    close() {
      this.entry = {
        id: null,
        emp_num: null,
        date: '',
        truck: null,
        job_num: '',
        location_id: null,
        eq_num: '',
        primary_unit_measure: null,
        gallons: '',
        approved: 0,
        current_hours: null,
        current_miles: null,
        note: null,
      };
      this.$emit('cancel');
    },

    async sendEntry(skip) {
      this.saving = true;
      const entryToSubmit = this.convertObjectValuesFromStringToNull({
        ...this.entry,
        id: this.existingRecord ? this.recordClickedOn.id : null,
        location_id: this.locations.filter((job) => {
          return job.job_num === this.entry.job_num;
        })[0].location_id,
        note: this.entry.note || null,
      });
      const isHoursMilesValid = skip ? true : await this.validateHoursMiles(entryToSubmit);
      const isFormValid = this.$refs.form.validate();
      if (!isHoursMilesValid || !isFormValid) {
        return;
      }
      const response = await this.setSlipTankRecord(entryToSubmit);
      this.saving = false;
      if (response) {
        this.close();
      }
    },

    // Takes in object, returns same object, but with nulls instead of empty strings
    convertObjectValuesFromStringToNull(obj) {
      const objToReturn = obj;
      Object.keys(obj).forEach((key) => {
        if (objToReturn[key] === '') {
          objToReturn[key] = null;
        }
      });
      return objToReturn;
    },

    async validateHoursMiles(record) {
      const userData = {
        eq_num: record.eq_num,
        unit_of_measure: record.primary_unit_measure,
        current_hour_or_mile_reading: (Number(record.primary_unit_measure)) ? record.current_miles : record.current_hours,
        date_of_reading: record.date,
      };
      const response = await this.checkMeterCall(userData);
      if (response && response.accurate) {
        return true;
      }
      if (!response.accurate) {
        await this.userAlert(`${response.reading_message}, Continue anyways?`, true);
        return false;
      }
      return false;
    },

    async setSlipTankRecord(record) {
      const server = `${this.$store.getters.server}controller.php`;
      const cstk = ['equipment', 'set', 'single', 'sliptankRecord'];
      const userData = record;
      const response = await this.fetchDataNew(cstk, userData, { alternate_url: server });
      if (!response.error) {
        return true;
      }
      // eslint-disable-next-line no-alert
      alert('There was an error saving, please try again');
      return false;
    },

    async getAllTrucks() {
      const server = `${this.$store.getters.server}controller.php`;
      const cstk = ['equipment', 'return', 'all', 'series17Equipment'];
      const response = await this.fetchDataNew(cstk, { alternate_url: server });
      if (!response.error && !response.empty) {
        return response.response.data;
      }
      if (!response.error) {
        return [];
      }
      return false;
    },

    async checkMeterCall(userData) {
      const server = `${this.$store.getters.server}controller.php`;
      const cstk = ['equipment', 'action', 'single', 'checkIfMeterReadingIsAccurate'];
      const response = await this.fetchDataNew(cstk, userData, { alternate_url: server });
      if (!response.error && !response.empty) {
        return response.response.data;
      }
      return (!response.error);
    },

    async deleteSliptankRecord(id) {
      const server = `${this.$store.getters.server}controller.php`;
      const cstk = ['equipment', 'delete', 'single', 'sliptankRecord'];
      const userData = {
        id,
      };
      const response = await this.fetchDataNew(cstk, userData, { alternate_url: server });
      if (!response.error) {
        return response.response.data;
      }
      return false;
    },

    // ALERT MANAGE FUNCTION
    userAlert(message, confirm) {
      this.alert = {
        ...this.alert, message, display: true, confirm,
      };
    },
  },
};
</script>
<style>
  .theme--dark.v-subheader {
    color: #ef9f0ef0;
  }
</style>
