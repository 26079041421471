import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{staticClass:"userAlert",attrs:{"value":_vm.display,"persistent":"","max-width":"90%","width":600}},[_c(VCard,{staticClass:"mx-auto",attrs:{"id":"userAlert","outlined":""}},[_c(VCardTitle,{staticClass:"align-items-center d-flex justify-space-between",attrs:{"id":"userAlertTitle"}},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('img',{attrs:{"src":"img/logo-124x80.png","alt":"Rotschy Inc","itemprop":"logo","width":"62","height":"40"}})]),_c(VDivider),_c(VCardText,{staticClass:"pa-5 subtitle-1",attrs:{"id":"userAlertMessage"}},[_vm._v(" "+_vm._s(_vm.parsedMessage)+" ")]),_c(VDivider),_c(VContainer,{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"id":"userAlertClose","color":"error"},on:{"click":function($event){return _vm.$emit('close', false)}}},[_c(VIcon,{staticClass:"pr-2",attrs:{"small":""}},[_vm._v(" mdi-close ")]),_vm._v(" Close ")],1),(_vm.confirm)?_c(VBtn,{staticClass:"ml-3",attrs:{"id":"confirm-alert","color":"success"},on:{"click":function($event){return _vm.$emit('save', true)}}},[_c(VIcon,{staticClass:"pr-2",attrs:{"small":""}},[_vm._v(" mdi-check ")]),_vm._v(" Confirm ")],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }