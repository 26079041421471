<template>
  <v-dialog
    :value="display"
    class="userAlert"
    persistent
    max-width="90%"
    :width="600"
  >
    <v-card
      id="userAlert"
      class="mx-auto"
      outlined
    >
      <v-card-title
        id="userAlertTitle"
        class="align-items-center d-flex justify-space-between"
      >
        {{ title }}
        <img
          src="img/logo-124x80.png"
          alt="Rotschy Inc"
          itemprop="logo"
          width="62"
          height="40"
        >
      </v-card-title>
      <v-divider />
      <v-card-text
        id="userAlertMessage"
        class="pa-5 subtitle-1"
      >
        {{ parsedMessage }}
      </v-card-text>
      <v-divider />
      <v-container class="d-flex justify-end">
        <v-btn
          id="userAlertClose"
          color="error"
          @click="$emit('close', false)"
        >
          <v-icon
            small
            class="pr-2"
          >
            mdi-close
          </v-icon>
          Close
        </v-btn>
        <v-btn
          v-if="confirm"
          id="confirm-alert"
          class="ml-3"
          color="success"
          @click="$emit('save', true)"
        >
          <v-icon
            class="pr-2"
            small
          >
            mdi-check
          </v-icon>
          Confirm
        </v-btn>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'AlertBox',
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Rotschy App',
    },
    message: {
      type: String,
      default: '',
    },
    confirm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    parsedMessage() {
      return this.message.replace('<br>', ' ');
    },
  },
};
</script>

<style>
#userAlertMessage {
  white-space: pre-line;
}
</style>
